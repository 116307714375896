/*
1.GLOBAL
├── 1.1. INPUT
├── 1.2. BTN
├── 1.3. DROPDOWN MENU COMPONENT
├── 1.4. SCROLL UP
├── 1.5. PROGRESS BAR
├── 1.6. BREADCRUMBS
├── 1.7. MENU
├── 1.8. ACCORDION
├── 1.9. DATE PICKER - DATE RANGE PICKER
├── 1.10. PASSWORD STRENGTH INDICATOR
├── 1.11. NEW ITEM INDICATOR
├── 1.12. MENU ITEM (SLIDER MENU ITEM)
├── 1.13. WIDGETS
├── 1.14. DATA-TABLE
├── 1.15. BETTING BUTTON
├── 1.16. COUPON PANEL
├── 1.17. SCROLLBOX
├── 1.18. SLIDER
├── 1.19. SCROLL SLIDER
├── 1.20. BETS LIST
├── 1.21. BURGER
├── 1.22. SWITCHER
├── 1.23. DOTS LOADING
├── 1.24. GAME ITEM
└── 1.25. WIDGET SPORT ITEM
*/

/*
|--------------------------------------------------------------------------
| == 1.GLOBAL
|--------------------------------------------------------------------------
*/
$color--body-background: #172F28;

html[lang="aa"] body {
    font-family: 'Almarai', sans-serif;
}

input:focus,
input:-webkit-autofill:focus,
input:-webkit-autofill {
    color: #fff !important;
    transition: all 50000000s;
}

// scrollbar
.body--with-cursor {
    *::-webkit-scrollbar-track {
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        background-color: transparent;
    }

    *::-webkit-scrollbar-thumb {
        background-color: #3F4F47;
    }
}

.ll-content-container-wrapper {
    @extend %G-content-container-wrapper;
}

.page-blank--desktop {
    @extend %G-page-blank--desktop;
    min-height: calc(100vh - #{$size-height--footer_desktop} - #{$size-height--header_desktop} - #{$size-height--header-menu_desktop} - #{$size-height--content-footer});
    max-height: calc(100vh - #{$size-height--footer_desktop} - #{$size-height--header_desktop} - #{$size-height--header-menu_desktop} - #{$size-height--content-footer});

    .page-blank__text {
        color: #fff;
    }
}

.page-blank--tablet,
.page-blank--mobile {
    @extend %G-page-blank--mobile;
    min-height: $size-max-height--page-container_tablet;
    max-height: $size-max-height--page-container_tablet;

    .page-blank__text {
        color: #fff;
    }
}

.page-blank--tablet {
    @extend %G-page-blank--tablet;
}

.page-wrapper--mobile {
    @extend %G-page-wrapper--tablet-mobile;

    margin-top: calc(#{$size-height--header_mobile} + #{$size-height--header-nav-menu_mobile});
    margin-bottom: $size-height--footer_mobile;
    background-color: $color--body-background;
    min-height: calc(100vh - #{$size-height--header_mobile} - #{$size-height--header-nav-menu_mobile} - #{$size-height--footer_mobile});
    //ios crutch
    @supports (-webkit-touch-callout: none) {
        min-height: calc(100vh - #{$size-height--header_mobile} - #{$size-height--header-nav-menu_mobile} - #{$size-height--footer_mobile} - 7rem);
    }

    &.page-wrapper--with-search-filter-panel {
        background-color: #171F1D;

        .prematch--mobile {
            background-color: #171F1D;
        }
    }

    &.page-wrapper--with-coupons {
        margin-bottom: calc($size-height--footer_mobile + 4rem);
        min-height: calc(100vh - #{$size-height--header_mobile} - #{$size-height--header-nav-menu_mobile} - #{$size-height--footer_mobile} - 4rem);
        @supports (-webkit-touch-callout: none) {
            min-height: calc(100vh - #{$size-height--header_mobile} - #{$size-height--header-nav-menu_mobile} - #{$size-height--footer_mobile} - 11rem);
        }
    }

    &.page-wrapper--home-page {
        min-height: fit-content !important;
    }

    &.page-wrapper--slots-battles {
        background: #171F1D;
    }
}

.page-wrapper--tablet {
    @extend %G-page-wrapper--tablet-mobile;

    margin-top: calc(#{$size-height--header_tablet} + #{$size-height--header-nav-menu_tablet});
    margin-bottom: $size-height--footer_tablet;
    min-height: calc(100vh - #{$size-height--header_tablet} - #{$size-height--header-nav-menu_tablet} - #{$size-height--footer_tablet});
    //ios crutch
    @supports (-webkit-touch-callout: none) {
        min-height: calc(100vh - #{$size-height--header_tablet} - #{$size-height--header-nav-menu_tablet} - #{$size-height--footer_tablet} - 7rem);
    }

    &.page-wrapper--home-page {
        min-height: fit-content !important;
    }

    &.page-wrapper--slots-battles {
        background: #171F1D;
    }
}

.page-wrapper--desktop {
    @extend %G-page-wrapper--desktop;
}

.page-wrapper--tablet,
.page-wrapper--desktop {
    background: $color--body-background;
}

.page-wrapper--desktop {
    &.page-wrapper--live,
    &.page-wrapper--sport {
        background: url('../../../../static/themes/theme/images/backgrounds/bg_sport.jpg') center calc(#{$size-height--header_tablet} + #{$size-height--header-nav-menu_tablet}) no-repeat;
        background-size: 100%;
        background-color: #0d1e18;
    }

    &.page-wrapper--slots {
        background: url('../../../../static/themes/theme/images/backgrounds/bg_slots.jpg') left calc(#{$size-height--header-nav-menu_tablet}) no-repeat fixed;
        background-size: 100%;
    }

    &.page-wrapper--slots-battles {
        background-color: #171F1D;
        background-image: url('../../../../static/themes/theme/images/slots-and-casino/slot-battles-main.jpg');
        background-attachment: fixed;
        background-size: cover;
    }
}

.ll-modal__scrollbox--mobile,
.ll-modal__scrollbox--tablet,
.ll-modal__scrollbox--desktop {
    @extend %G-ll-modal__scrollbox;

    background: #2d3d37;
    color: #fff;
}

// preloader {
.ll-preloader {
    @extend %G-ll-preloader;
    background-color: #172F283C;

    &.ll-preloader__prematch-detail {
        background-color: #172F283C;
    }
}

// search-highlite
.event__search-highlite {
    font-weight: 600;
}

// blink-effect
.blink-effect {
    animation: blink-animation 3s ease-in-out;
}

@keyframes blink-animation {
    from {
        opacity: .2;
        color: #172f28;
    }
    20% {
        opacity: 1;
    }
    80% {
        color: #172f28;
    }
    to {
        color: inherit;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.1. INPUT
|--------------------------------------------------------------------------
*/

.ll-input {
    @extend %G-ll-input;

    &:not(.coupons__amount-input):not(.games__search-input) {
        .dx-texteditor.dx-editor-outlined {
            background: #171F1D;
            box-shadow: inset 0 0 0 1px #3F4F47;
        }

        .dx-texteditor-input {
            color: #fff;
        }
    }

    &.ll-input--focused,
    &.ll-input--labeled-up {
        .ll-input__label:not(.ll-input__label--top) {
            background: linear-gradient(180deg, transparent 55%, #171F1D 45%);
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 1.2. BTN
|--------------------------------------------------------------------------
*/

.ll-btn2 {
    @extend %G-ll-btn2;

    background: #F1DBAA;
    color: #000;

    .preloader-box {
        background: inherit;
    }

    &.ll-btn--accent {
        background: #DED6C2;
        color: #000;
    }

    &.ll-btn--full-rounded {
        &:active {
            background: #DED6C2;
        }
    }

    &.ll-btn--outlined {
        border: 1px solid #DED6C2;
        color: #DED6C2;
    }

    &.ll-btn--inline {
        color: inherit;

        &:active {
            color: #DED6C2;
        }
    }

    &.ll-btn--square--mobile {
        background: #DED6C2;
    }

    &.ll-btn--square--tablet,
    &.ll-btn--square--desktop {
        background: #F1DBAA;

        &:active {
            background: #DED6C2;
        }
    }

    &.ll-btn--square-outlined--mobile,
    &.ll-btn--square-outlined--desktop,
    &.ll-btn--square-outlined--tablet {
        background: transparent;
        color: #172F28;
    }

    &.ll-btn--square-outlined--mobile {
        border: 1px solid #DED6C2;
    }

    &.ll-btn--square-outlined--desktop,
    &.ll-btn--square-outlined--tablet {
        border: 1px solid #DED6C2;

        &:active {
            color: #172F28;
            border: 1px solid #172F28;
        }
    }
}

.ll-btn {
    @extend %G-ll-btn;

    background: #F1DBAA;
    color: #000;

    .dx-button-link {
        color: #000;
        font-weight: 500;
    }

    &.ll-btn--accent {
        background: #DED6C2;
        color: #000;
    }

    &.ll-btn--full-rounded {
        &:active {
            background: #DED6C2;
            opacity: 1;
        }
    }

    &.ll-btn--outlined {
        border: 1px solid #DED6C2;
        color: #DED6C2;
    }

    &.ll-btn--square--mobile {
        background: #F1DBAA;
    }

    &.ll-btn--square--tablet,
    &.ll-btn--square--desktop {
        background: #F1DBAA;

        &:active {
            background: #DED6C2;
            border-color: #DED6C2;
            opacity: 1;
        }
    }

    &.ll-btn--square-outlined--mobile,
    &.ll-btn--square-outlined--desktop,
    &.ll-btn--square-outlined--tablet {
        background: transparent;
        color: #172F28;
    }

    &.ll-btn--square-outlined--mobile {
        border: 1px solid #F1DBAA;
    }

    &.ll-btn--square-outlined--desktop,
    &.ll-btn--square-outlined--tablet {
        border: 1px solid #F1DBAA;

        &:active {
            color: #DED6C2;
            border: 1px solid #DED6C2;
        }
    }
}

.body--with-cursor {
    .ll-btn {
        &:hover {
            background: #DED6C2;
        }

        &.ll-btn--full-rounded:hover {
            background: #DED6C2;
            color: #000;
            opacity: 1;
        }

        .ll-btn--inline:hover {
            color: #DED6C2;
        }

        &.ll-btn--outlined:hover {
            border: 1px solid #DED6C2;
            background: #DED6C2;
            color: #000;
        }

        &.ll-btn--square--tablet,
        &.ll-btn--square--desktop {
            &:hover {
                background: #DED6C2;
                border-color: #DED6C2;
                opacity: 1;
            }
        }

        &.ll-btn--square-outlined--desktop,
        &.ll-btn--square-outlined--tablet {
            &:hover {
                border-color: #172F28;
                background: transparent;
                opacity: 1;
            }
        }
    }

    .ll-btn2 {
        &:hover {
            background: #F1DBAA;
        }

        &.ll-btn--inline:hover {
            color: #DED6C2;
        }

        &.ll-btn--square--tablet,
        &.ll-btn--square--desktop {
            &:hover {
                background: #DED6C2;
                border-color: #DED6C2;
            }
        }

        &.ll-btn--square-outlined--desktop,
        &.ll-btn--square-outlined--tablet {
            &:hover {
                border-color: #172F28;
                background: transparent;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 1.3. DROPDOWN MENU COMPONENT
|--------------------------------------------------------------------------
*/

.ll-dropdown-wrapper {
    @extend %G-ll-dropdown-wrapper;
}


/*
|--------------------------------------------------------------------------
| == 1.4. SCROLL UP
|--------------------------------------------------------------------------
*/

.scroll-to-top,
.scroll-to-top--shifted,
.scroll-to-top--semi-shifted {
    @extend %G-scroll-to-top;

    border: 0.2em solid #D2BD8D;
    background-color: rgba(0, 0, 0, 0.2);
    transition: border 0.3s linear, background-color 0.3s linear;

    .scroll-up__icon {
        color: #D2BD8D;
        font-size: 2rem;
    }
}

.scroll-to-top--shifted {
    @extend %G-scroll-to-top--shifted;
}

.scroll-to-top--semi-shifted {
    @extend %G-scroll-to-top--semi-shifted;
}

/*
|--------------------------------------------------------------------------
| == 1.5. PROGRESS BAR
|--------------------------------------------------------------------------
*/

.ll-progress-bar--determinate,
.ll-progress-bar--indeterminate {
    @extend %G-ll-progress-bar;
}

.ll-progress-bar--determinate {
    @extend %G-ll-progress-bar--determinate;
}

.ll-progress-bar--indeterminate {
    @extend %G-ll-progress-bar--indeterminate;

    .ll-progress-bar__value::before {
        background-color: inherit;
    }

    .ll-progress-bar__value::after {
        background-color: inherit;
    }
}

.ll-progress-bar__value {
    background-color: #E5BA55;
}

/*
|--------------------------------------------------------------------------
| == 1.6. BREADCRUMBS
|--------------------------------------------------------------------------
*/
.ll-breadcrumbs {
    @extend %G-ll-breadcrumbs;
}

.ll-breadcrumbs__sport {
    @extend %G-ll-breadcrumbs__sport;
}

.ll-breadcrumbs__title--desktop,
.ll-breadcrumbs__title--tablet,
.ll-breadcrumbs__title--mobile {
    .ll-breadcrumbs__content {
        @extend %G-ll-breadcrumbs__title;

        .ll-breadcrumbs__menu-item--link {
            &:active {
                text-decoration: underline;
            }
        }
    }
}

.ll-breadcrumbs__title--desktop,
.ll-breadcrumbs__title--tablet {
    .ll-breadcrumbs__content {
        @extend %G-ll-breadcrumbs__title--desktop;
        font-size: 14px;
        color: #FFFFFF;

        .ll-breadcrumbs__menu-item--link {
            color: #FFFFFF;
        }
    }
}

.ll-breadcrumbs__title--mobile {
    .ll-breadcrumbs__content {
        @extend %G-ll-breadcrumbs__title--mobile;
        font-size: 12px;
        color: #fff;

        .ll-breadcrumbs__menu-item--link {
            color: #fff;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 1.7. MENU
|--------------------------------------------------------------------------
*/

.menu {
    @extend %G-menu;
}

/*
|--------------------------------------------------------------------------
| == 1.8. ACCORDION
|--------------------------------------------------------------------------
*/

.ll-toggleable__content--collapsed {
    @extend %G-ll-toggleable__content;
}

.ll-accordion,
.ll-accordion--active,
.ll-accordion--disabled {
    @extend %G-ll-accordion;

    .ll-accordion__icon::before,
    .ll-accordion__icon--right::before {
        font-size: 14px;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.9. DATE PICKER - DATE RANGE PICKER
|--------------------------------------------------------------------------
*/

.ll-date-picker--mobile,
.ll-date-picker--tablet,
.ll-date-picker--desktop {
    @extend %G-ll-date-picker;
}

.ll-date-picker--mobile {
    @extend %G-ll-date-picker--mobile;

    .react-datepicker__month {
        & [class*='--selected'],
        & [class*='-selected'] {
            background: #E5BA55;
        }
    }
}

.ll-date-picker--desktop,
.ll-date-picker--tablet {
    flex: 1;

    .react-datepicker-wrapper {
        width: 100%;
    }

    .react-datepicker__month {
        & [class*='--selected'],
        & [class*='-selected'] {
            background: #E5BA55;
            color: #000;
        }
    }

    .react-datepicker__day.react-datepicker__day--keyboard-selected {
        background: none;
        color: #000;
    }

    .react-datepicker__day--keyboard-selected.react-datepicker__day--disabled {
        background: none;
        color: #ccc;
    }

    .react-datepicker__day--today {
        background: #2D3D37;
    }
}

.ll-date-range-picker--mobile.ll-date-range-picker--user-cabinet {
    @extend %G-ll-date-range-picker--mobile;

    .ll-date-picker--mobile {
        border-right: 1px solid #2D3D37;
        background: #DFDDC2;
        color: #000;
    }

    .ll-date-range-picker__request-btn {
        background: #E5BA55;
    }

    .ll-date-range-picker__picker-input {
        border: none;
        color: #000;
    }

    .ll-date-picker__icon {
        color: #000;
    }
}

.ll-date-range-picker--tablet,
.ll-date-range-picker--desktop {
    @extend %G-ll-date-range-picker--tablet-desktop;

    .ll-date-range-picker__values-box {
        border-radius: 3px;
        border: 1px solid #BEBA9A;
        background: #957D4B;
        color: #000;
    }

    .ll-date-range-picker__input {
        background-color: inherit;
        color: inherit;
        cursor: inherit;
    }

    .ll-date-range-picker__dropdown-box {
        &::before {
            border-right: 7px solid transparent;
            border-bottom: 7px solid #172F28;
            border-left: 7px solid transparent;
        }

        box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        background-color: #172F28;
    }

    .ll-date-range-picker__picker-input {
        box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.1);
        border: 1px solid #E5BA55;
        border-radius: 4px;
        color: #fff;

        .dx-texteditor-input {
            color: #fff;
        }
    }

    .ll-date-range-picker__range-btn {
        border: 1px solid #E5BA55;
        background: transparent;
        color: #E5BA55;
    }

    .ll-date-range-picker__picker-input .dx-texteditor.dx-editor-underlined::before,
    .ll-date-range-picker__picker-input .dx-texteditor.dx-editor-underlined::after {
        display: none;
    }

    .ll-date-range-picker__action-btn {
        background: #C2B288;
        color: #000;

        &.ll-btn--accent {
            box-shadow: none;
            background: #E5BA55;
        }
    }

    .ll-date-range-picker__picker {
        box-shadow: 0 0 34px 0 rgba(63, 66, 87, 0.1);
        border: 1px solid #000000;
        background: #3F4F47;
        color: #fff;

        &::before {
            border-top: 1px solid #000000;
            border-left: 1px solid #000000;
            background-color: #3F4F47;
        }

        .react-datepicker__header {
            background-color: #3F4F47;
        }

        .react-datepicker__navigation--previous {
            border-right-color: #E5BA55;
        }

        .react-datepicker__navigation--next {
            border-left-color: #E5BA55;
        }

        .react-datepicker__current-month,
        .react-datepicker-time__header,
        .react-datepicker-year-header {
            color: #E5BA55;
        }

        .react-datepicker__day-name,
        .react-datepicker__day,
        .react-datepicker__time-name {
            color: #fff;

            &.react-datepicker__day--selected {
                color: #000;
            }
        }

        .react-datepicker__day--disabled {
            color: #ffffff66;
        }
    }
}

.ll-date-range-picker--tablet {
    @extend %G-ll-date-range-picker--tablet;

    border: 1px solid #BEBA9A;

    .ll-date-range-picker__values-box > i {
        color: #fff;
        font-size: 1.1rem;
    }
}

.ll-date-range-picker--desktop {
    @extend %G-ll-date-range-picker--desktop;

    .ll-date-range-picker__values-down-icon {
        font-size: 1rem;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.10. PASSWORD STRENGTH INDICATOR
|--------------------------------------------------------------------------
*/

.password-strength {
    @extend %G-password-strength;

    .dx-progressbar-container,
    .dx-progressbar-animating-container {
        height: 38px;
        animation: none;
        background: linear-gradient(
                        90deg,
                        rgba(255, 138, 180, 1) 0%,
                        rgba(254, 255, 143, 1) 52%,
                        rgba(133, 255, 135, 1) 100%
        );
    }

    .dx-progressbar-range {
        transition: all .2s;
    }

    .password-strength__pwd-bar__10-percent,
    .password-strength__pwd-bar__20-percent,
    .password-strength__pwd-bar__30-percent {
        .dx-progressbar-range {
            background-color: #ff0000;
        }
    }

    .password-strength__pwd-bar__40-percent,
    .password-strength__pwd-bar__50-percent,
    .password-strength__pwd-bar__60-percent {
        .dx-progressbar-range {
            background-color: #ffdc00;
        }
    }

    .password-strength__pwd-bar__70-percent,
    .password-strength__pwd-bar__80-percent,
    .password-strength__pwd-bar__90-percent {
        .dx-progressbar-range {
            background-color: #d0ff00;
        }
    }

    .password-strength__pwd-bar__100-percent {
        .dx-progressbar-range {
            background-color: #7dff45;
        }
    }


    .password-strength__texts {
        color: #000;
        font-size: 1.4rem;
        line-height: 3.8rem;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.11. NEW ITEM INDICATOR
|--------------------------------------------------------------------------
*/
.ll-submenu-item__indicator {
    @extend %G-new-item-indicator;
    color: #fff;
    font-size: 1.3rem;
    background: linear-gradient(0deg, #b78628, #fcc201 77%, #fdf5d6), #ae9a76;
    font-weight: 700;
}

/*
|--------------------------------------------------------------------------
| == 1.12. MENU ITEM (SLIDER MENU ITEM)
|--------------------------------------------------------------------------
*/
.menu-item--desktop {
    @extend %G-menu-item--desktop;
    color: #fff;
}

.menu-item--tablet {
    @extend %G-menu-item--tablet;
    color: #fff;
    font-size: 1.2rem;
}

.menu-item--mobile {
    @extend %G-menu-item--mobile;
    color: #fff;
    font-size: 1rem;

    .menu-item__label {
        font-size: .9rem;
    }
}

.menu-item-accordion-content {
    @extend %G-menu-item-accordion-content;
}

.ll-footer__slider--mobile {
    .menu-item--tablet,
    .menu-item--mobile {
        background-color: #2D3D37;

        &.ll-footer__item--accent {
            color: #D2BD8D;
        }
    }
}

.menu-item__img {
    background-color: #fff;
    border: 1px solid #ffffff50;
    border-radius: 50%;
}

.menu-item__icon {
    font-size: 1.6rem;
}

.menu-item__label {
    text-align: center;
    @include mq('desktop') {
        font-size: 1.5rem;
    }
    @include mq('mobile') {
        font-size: 1rem;
        line-height: 1rem;
    }
}

.ll-submenu-item {
    @extend %G-ll-submenu-item;
}

.slots-casino-menu__sub-items,
.header-upper-menu__sub-items {
    @extend %G-header-upper-menu__sub-items;
}

.menu-sub-item--mobile {
    @extend %G-menu-sub-item--mobile;
}

.menu-sub-item--tablet {
    @extend %G-menu-sub-item--tablet;
}

.ll-submenu__item,
.ll-submenu__item--active {
    font-size: 15px;
}

.ll-dropdown-menu__text,
.ll-submenu__text {
    user-select: none;
}

.ll-submenu-item__icon {
    font-size: 1.6rem;
}

.menu-sub-item--desktop {
    &.slots-casino-menu__sub-items,
    &.header-upper-menu__sub-items {
        z-index: 100;

        .ll-scrollbox--desktop {
            @extend %G-slots-casino__header-menu-sub-items--desktop;
            color: #fff;
            background: $slots-casino__header-menu-sub-items--bg;
        }

        .slots-casino-menu__provider-without-img,
        .menu-item__img {
            font-size: 2rem;
            background-color: #181818;
            border: 1px solid #ffffff50;
        }

        .menu-item__icon {
            font-size: 2rem;
        }

        .sub-item:hover,
        .sub-item--active {
            color: #C2B288;
        }
    }

    .sub-item:hover,
    .sub-item--active {
        .menu__item-label {
            color: #957D4B;
        }
    }

    .sub-item,
    .sub-item--active {
        @extend %G-slots-casino__header-menu-sub-item--desktop;
    }
}

.menu-sub-item--mobile {
    &.slots-casino-menu__sub-items,
    &.header-upper-menu__sub-items {
        background: $slots-casino__header-menu-sub-items--bg;
        border: $slots-casino__header-menu-sub-items--border;

        .ll-scrollbox--mobile {
            @extend %G-slots-casino__header-menu-sub-items--mobile;
            color: #fff;
        }
    }

    .sub-item,
    .sub-item--active {
        @extend %G-slots-casino__header-menu-sub-item--mobile;
        border-bottom: 1px solid #ffffff50;
    }

    &.slots-casino-menu__sub-items > div {

        .sub-item,
        .sub-item--active {
            border-right: 1px solid #ffffff50;

            .slots-casino-menu__item-icon {
                background-color: #000;
                border: 1px solid #ffffff50;
            }
        }
    }
}

.menu-sub-item--tablet {
    &.slots-casino-menu__sub-items,
    &.header-upper-menu__sub-items {
        @extend %G-slots-casino__header-menu-sub-items--tablet;
        background: $slots-casino__header-menu-sub-items--bg;
        color: #fff;

        .ll-scrollbox--tablet {
            @include flex-config(
                    $flex-wrap: wrap,
                    $align-items: center,
                    $justify-content: flex-start
            );
        }

        .sub-item,
        .sub-item--active {
            @extend %G-slots-casino__header-menu-sub-item--tablet;
            border-bottom: 1px solid #ffffff50;
            border-right: 1px solid #ffffff50;
        }

        .sub-item--active {
            .menu-item__label,
            .menu__item-label {
                color: #957D4B;
            }
        }

        .menu-item__img {
            background-color: transparent;
        }
    }

    &.slots-casino-menu__sub-items > div {
        .sub-item--active {
            .menu__item-label {
                color: #957D4B;
            }
        }

        .sub-item,
        .sub-item--active {
            .slots-casino-menu__item-icon {
                background-color: #000;
                border: 1px solid #ffffff50;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 1.13. WIDGETS
|--------------------------------------------------------------------------
*/
.ll-widget--hidden {
    display: none;
}

/*
|--------------------------------------------------------------------------
| == 1.14. DATA-TABLE
|--------------------------------------------------------------------------
*/
.data-table--mobile,
.data-table--tablet,
.data-table--desktop {
    @extend %G-data-table;

    .data-table__summary {
        background: #3F4F47;
        color: #fff;
    }

    .data-table__summary-item {
        border: 1px solid #172F28;

        &.data-table__summary-item--odds {
            border-left: 1px solid #172F28;
        }
    }

    .data-table__page-size-dropdown {
        box-shadow: inset 0 0 1px 1px #DCBA74;
        background: #DCBA74;
        color: #000;
    }

    .data-table__page-size-dropdown-menu {
        border: 1px solid #172F28;

        .ll-dropdown-menu-item,
        .ll-dropdown-menu-item--active {
            background: #2D3D37;
            color: #fff;
        }

        .ll-dropdown-menu-item--active {
            background: #172F28;
            border: 1px solid #2D3D37;
        }
    }

    .data-table__pagination-numbers {
        svg {
            fill: #ffffff;
        }

        & * {
            color: #fff;

            &.Mui-selected {
                background: #DCBA74 !important;
                color: #000;
            }
        }
    }
}

.data-table--empty {
    @extend %G-data-table--empty;

    background: #3F4F47;
    color: #fff;
}

.data-table--mobile {
    @extend %G-data-table--mobile;

    .dx-datagrid {
        background: transparent;
    }

    .dx-datagrid-headers {
        background: #3F4F47;

        .dx-datagrid-table td {
            background: #3F4F47 !important;
            color: #fff !important;
        }
    }

    .dx-datagrid-table {
        .dx-row > td,
        .dx-row + .dx-adaptive-detail-row > td {
            background: #F1EFE7;
            color: #000;
        }

        .dx-row-alt > td,
        .dx-row-alt + .dx-adaptive-detail-row > td {
            background: #ECEADB;
        }

        .dx-datagrid-adaptive-more::before {
            color: #E5BA55;
        }

        .dx-field-item {
            .dx-field-item-label-text {
                color: #957D4B;
                font-weight: 400;
            }
        }
    }

    &#live-calendar-table {
        background: #2D3D37;

        .dx-datagrid-header-panel {
            background-color: #3f4f47;

            input {
                color: #fff;
            }

            .dx-placeholder {
                color: #dfd9d9d9;
            }
        }

        .dx-row.dx-data-row {
            background: #404E48;

            .live-calendar__item-icon {
                background: #172F28;
                color: #fff;
            }

            .live-calendar__item-text {
                color: #fff;
            }
        }

        .dx-row.dx-data-row.dx-row-alt {
            background: #475A53;

            .live-calendar__item-icon {
                background: #172F28;
            }
        }
    }
}

.data-table--tablet,
.data-table--desktop {
    @extend %G-data-table--tablet--desktop;

    .dx-row.dx-data-row.table-row__bet--cashouted {
        color: #147db1;
    }
}

.data-table--tablet {
    @extend %G-data-table--tablet;
}

.data-table--desktop {
    @extend %G-data-table--desktop;

    .dx-datagrid-headers {
        background-color: #3F4F47;

        .dx-datagrid-table {
            .dx-row > td {
                background: #3F4F47 !important;
                color: #fff;

                .dx-sort,
                .dx-datagrid-text-content {
                    color: #fff !important;
                }
            }

            .dx-header-filter {
                color: #fff;
            }
        }
    }

    .data-table__cell--bet-result-link {
        color: #957D4B;
    }

    .dx-row.dx-data-row {
        &.dx-selection,
        &.dx-selection > td {
            background: #172F28;
            color: #E5BA55;
        }
    }

    .dx-master-detail-row .dx-master-detail-cell {
        background-color: #172F28 !important;

        .dx-datagrid-focus-overlay:after {
            display: none;
        }
    }

    .dx-datagrid-expand .dx-datagrid-group-opened,
    .dx-datagrid-expand .dx-datagrid-group-closed,
    .dx-datagrid-expand + td.dx-group-cell {
        color: #000;
    }
}

.dx-swatch-dev-extreme-table-dark-custom-scheme {
    .dx-datagrid-headers .dx-datagrid-table .dx-row > td,
    .dx-datagrid-headers .dx-datagrid-table .dx-row > td .dx-header-filter,
    .dx-datagrid-headers .dx-datagrid-table .dx-row > td .dx-datagrid-text-content {
        background-color: #3A4E47 !important;
        color: #fff !important;
    }
}

.dx-overlay-wrapper.dx-header-filter-menu.dx-popup-wrapper {
    z-index: 1600 !important;
}

/*
|--------------------------------------------------------------------------
| == 1.15. BETTING BUTTON
|--------------------------------------------------------------------------
*/
.bet,
.bet--column1,
.bet--column2,
.bet--column3 {
    @extend %G-bet;
}

.bet--column2 {
    @extend %G-bet--column2;
}

.bet--column3 {
    @extend %G-bet--column3;
}

.bet--draw {
    @extend %G-bet--draw;
}

/*
|--------------------------------------------------------------------------
| == 1.17. SCROLLBOX
|--------------------------------------------------------------------------
*/
.ll-scrollbox--mobile,
.ll-scrollbox--tablet {
    @extend %G-ll-scrollbox;
}

.ll-scrollbox--desktop {
    @extend %G-ll-scrollbox;
}

.scroll-to-top--shifted {
    @extend %G-scroll-to-top--shifted;
}

.scroll-to-top--semi-shifted {
    @extend %G-scroll-to-top--semi-shifted;
}

.mobile-fix {
    min-height: 100px;
}

/*
|--------------------------------------------------------------------------
| == 1.18. SLIDER
|--------------------------------------------------------------------------
*/
.slider {
    @extend %G-slider;

    &.slider--oversized > div {
        width: calc(100% - #{$size-width--slider-arrow_mobile + px} * 2);
    }

    .slider__arrow {
        min-width: $size-width--slider-arrow_mobile + px;
        max-width: $size-width--slider-arrow_mobile + px;
        color: #172F28;
    }

    &.slider--tablet {
        &.slider--oversized > div {
            width: calc(100% - #{$size-width--slider-arrow_tablet + px} * 2);
        }

        .slider__arrow {
            min-width: $size-width--slider-arrow_tablet + px;
            max-width: $size-width--slider-arrow_tablet + px;
        }
    }

    &.slider--desktop {
        &.slider--oversized > div {
            width: calc(100% - #{$size-width--slider-arrow_desktop + px} * 2);
        }

        .slider__arrow {
            min-width: $size-width--slider-arrow_desktop + px;
            max-width: $size-width--slider-arrow_desktop + px;
        }
    }
}


//hovers
.body--with-cursor {
    .data-table--mobile,
    .data-table--tablet,
    .data-table--desktop {
        .dx-datagrid-headers {
            .dx-datagrid-table {
                .dx-row > td:hover {
                    background: #3F4F47 !important;
                    color: #fff !important;

                    .dx-sort,
                    .dx-datagrid-text-content {
                        color: #fff !important;
                    }
                }
            }
        }

        .dx-row > td {
            .data-table__cell--bet-result-link:hover {
                color: #E5BA55;
            }
        }
    }

    .dx-swatch-dev-extreme-table-dark-custom-scheme {
        .dx-datagrid-headers .dx-datagrid-table .dx-row > td {
            &:hover:not(.dx-command-select):not(.dx-command-expand):not(.dx-editor-cell):not(.dx-command-edit):not(.dx-datagrid-group-space),
            &:hover:not(.dx-command-select):not(.dx-command-expand):not(.dx-editor-cell):not(.dx-command-edit):not(.dx-datagrid-group-space) .dx-datagrid-text-content {
                background-color: #3A4E47 !important;
                color: #fff !important;
            }
        }
    }

    .data-table--mobile,
    .data-table--tablet,
    .data-table--desktop {
        .data-table__page-size-dropdown-menu {
            .ll-dropdown-menu-item,
            .ll-dropdown-menu-item--active {
                &:hover {
                    background: #D2BD8D;
                    color: #000;
                }
            }
        }

        .data-table__pagination-numbers button:hover,
        .data-table__pagination-numbers svg:hover {
            background: #D2BD8D;
            color: #000;
            fill: #000;
        }

        .dx-row.dx-data-row {
            &.dx-selection:hover,
            &.dx-selection:hover > td {
                background: #172F28;
                color: #E5BA55;
            }
        }
    }

    .react-datepicker__day,
    .react-datepicker__month-text,
    .react-datepicker__quarter-text,
    .react-datepicker__year-text {
        &:hover {
            background: #E5BA55;
            color: #000;
        }
    }

    .ll-date-range-picker--desktop {
        .ll-date-range-picker__range-btn:hover {
            background: #DED6C2;
            border-color: #DED6C2;
            color: #000;
        }

        .ll-date-range-picker__action-btn:hover {
            background: none;
            box-shadow: inset 0 0 1px 1px #C2B288;
            color: #C2B288;

            &.ll-btn--accent {
                background: #DED6C2;
                color: #000;
            }
        }

        .react-datepicker__navigation--previous:hover {
            border-right-color: #fff;
        }

        .react-datepicker__navigation--next:hover {
            border-left-color: #fff;
        }
    }

    .ll-breadcrumbs__title--desktop,
    .ll-breadcrumbs__title--tablet,
    .ll-breadcrumbs__title--mobile {
        .ll-breadcrumbs__content {
            .ll-breadcrumbs__menu-item--link {
                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .slider__arrow:hover {
        color: #fff;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.19. SCROLL SLIDER
|--------------------------------------------------------------------------
*/

.scroll-slider {
    @extend %G-scroll-slider;

    .scroll-slider__prev,
    .scroll-slider__next {
        color: #FFFFFF;
        background-color: #2D3D37B3;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.20. BETS LIST
|--------------------------------------------------------------------------
*/
.bets-list {
    @extend %G-bets-list;

    .bet-list__no-data-text {
        border: 1px solid #C1BD9E;
        background: #E1DDB3;
        color: #464646;
    }

    .dx-datagrid {
        background-color: transparent;
    }

    .dx-datagrid-rowsview {
        border: none;
    }

    .data-table--desktop {
        .data-table__pagination {
            border: 1px solid #C1BD9E;
            background: #E1DDB3;
        }
    }

    .bets-list__bet {
        border: 1px solid #DFDDC2;
        background: #EEEADC;

        &.opened {
            border-color: #957D4B;
            background: #C2B080;
        }

        .bets-list__bet-money {
            &.bets-list__bet-money--won {
                .bets-list__bet-money-item-value {
                    color: #208d04;
                }

                .bets-list__bet-status {
                    background: #208d04;
                    color: #fff;
                }
            }

            &.bets-list__bet-money--lost {
                .bets-list__bet-money-item-value {
                    color: #D90000;
                }

                .bets-list__bet-status {
                    background: #D90000;
                    color: #fff;
                }
            }

            &.bets-list__bet-money--cashouted {
                .bets-list__bet-money-item-value {
                    color: #147db1;
                }

                .bets-list__bet-status {
                    background: #147db1;
                    color: #fff;
                }
            }

            &.bets-list__bet-money--deleted {
                text-decoration: line-through;

                .bets-list__bet-money-item-value,
                .bets-list__bet-status {
                    color: #979797;
                }
            }
        }
    }

    .bets-list__bet-time::before {
        color: #464646;
    }

    .bets-list__match {
        background: #DED6C2;

        &:nth-child(2n) {
            background: #D4CCB9;
        }

        &.won {
            .bets-list__status {
                color: #208d04;
            }
        }

        &.lost {
            .bets-list__status {
                color: #D90000;
            }
        }
    }

    .bets-list__match-odds {
        color: #172F28;
    }

    .bets-list__match-time {
        color: #464646;
    }

    .bets-list__match-type {
        color: #000;
    }

    .bets-list__match-type--bet-builder {
        &::before {
            border: 1px solid #464646;
            box-shadow: 0 0 0 2px #DED6C2;
            background: #DED6C2;
        }

        &::after {
            border-left: 1px solid #464646;
        }
    }

    .bets-list__bet-btn {
        background: #E5BA55;
        color: #000;

        .dx-loadindicator-segment-inner {
            border-color: #fff #fff transparent !important;
        }
    }

    .bets-list__bet-btn--delete {
        box-shadow: inset 0 0 1px 1px #464646;
        background: none;
    }

    .bets-list__bet-btn-icon--delete {
        color: #464646;
    }

    .bets-list__bet.bets-list__bet--active-bets {
        .bets-list__bet-money-item-value {
            color: #172F28;
        }
    }

    .bets-list__bet.bets-list__bet--bet-history {
        .bets-list__match-name {
            color: #000;
            font-weight: 400;
        }
    }

    //    Bet Details
    .bets-list__group {
        background: #ECEADB;

        &:nth-child(2n) {
            background: #F8F7E6;
        }
    }

    .bets-list__group-info {
        color: #000;
    }

    .bets-list__group-combination {
        color: #000;

        &.won {
            color: #208d04;
        }

        &.lost {
            color: #d90000;
        }

        &.void {
            color: #979797;
        }
    }

    .bets-list__group-item {
        background-color: #f1efe7;
        
        &:not(:last-of-type) {
            border-bottom: 1px solid #716F61;
        }

        &.won {
            .bets-list__group-item-status {
                background: #208d04;
                color: #fff;
            }
        }

        &.lost {
            .bets-list__group-item-status {
                background: #D90000;
                color: #fff;
            }
        }
    }

    .bets-list__group-item-info {
        color: #464646;
    }

    .bets-list__info-odd-type {
        color: #000;
    }
}

.body--with-cursor {
    .bets-list {
        .bets-list__bet-collapse-arrow:hover {
            color: #C73738;
        }

        .bets-list__bet-btn:hover {
            box-shadow: inset 0 0 1px 1px #435941;
            background: transparent;
            color: #435941;

            &.bets-list__bet-btn--delete {
                box-shadow: inset 0 0 1px 1px #000;
            }

            .bets-list__bet-btn-icon--delete {
                color: #000;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 1.21. BURGER
|--------------------------------------------------------------------------
*/

.ll-burger-icon,
.ll-burger-icon--opened {
    @extend %G-ll-burger-icon;

    div {
        background-color: #fff;
    }
}

.ll-burger-icon {
    @extend %G-ll-burger-icon--closed;
}

.ll-burger-icon--opened {
    @extend %G-ll-burger-icon--opened;
}

/*
|--------------------------------------------------------------------------
| == 1.22. SWITCHER
|--------------------------------------------------------------------------
*/

span.status-filter-switcher {
    @extend %G-status-filter-switcher;

    .status-filter-switcher-switch-base {
        color: #957d4b !important;
    }

    .status-filter-switcher-track {
        background-color: #000 !important;
        opacity: 1 !important;
    }
}

/*
|--------------------------------------------------------------------------
| == 1.23. DOTS LOADING
|--------------------------------------------------------------------------
*/

.ll-dots-loading {
    @extend %G-ll-dots-loading;

    &,
    &::before,
    &::after {
        background-color: #957d4b;
        color: #957d4b;
        animation: dotLoading .8s infinite alternate;
    }

    @keyframes dotLoading {
        0%,
        50% {
            background-color: #957d4b;
        }
        50%,
        100% {
            background-color: #DADAD9;
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 1.24. GAME ITEM
|--------------------------------------------------------------------------
*/

.game-img {
    @extend %G-game-img;

    .game__provider-without-img {
        background: #172F28;
        color: #fff;
        border: 1px solid #2D3D37;
    }

    .game-img__wrp--without-img {
        .game__provider-without-img {
            .game__provider-game-name {
                background-color: #3a4e47E5;
                color: #fff;
            }
        }

        .game__provider-with-img {
            .ll-popover-wrapper {
                background-color: #3a4e47E5;
                color: #fff;
            }
        }
    }
}