/*
3.PAGES
└── 3.6. MY ACCOUNT PAGE
    ├── 3.6.1 MY INFORMATION
    ├── 3.6.2 PAYMENTS
    ├── 3.6.4 ACCOUNT HISTORY
    ├── 3.6.5 BET HISTORY
    ├── 3.6.6 CASINO REPORT
    ├── 3.6.7 MY LIMITS
    └── 3.6.8 GOOGLE AUTHORIZATION
*/

/*
|--------------------------------------------------------------------------
|  == 3.6. MY ACCOUNT PAGE
|--------------------------------------------------------------------------
*/

.my-account--desktop,
.my-account--tablet,
.my-account--mobile {
    background: #171F1D;

    @extend %G-my-account;

    .bet-delete-icon {
        color: #464646;
    }
}

.my-account--tablet,
.my-account--mobile {
    @extend %G-my-account--mobile;

    .my-account-section {
        border: 1px solid #475A53;
    }

    .my-account-section__title {
        background: #475A53;
        color: #fff;
    }

    .my-account-section--secondary {
        .my-account-section__title {
            background: #3F4F47;
        }
    }
}

.my-account__title--mobile {
    @extend %G-my-account__title--mobile;

    background: #172F28;
    color: #E5BA55;

    .my-account__title-back-icon {
        color: #D2BD8D;
    }
}

.my-account--tablet,
.my-account--desktop {
    @extend %G-my-account--tablet-desktop;
}

.my-account--tablet {
    @extend %G-my-account--tablet;

    .my-account__title {
        background: #172F28;
        color: #E5BA55;
    }
}

.my-account--desktop {
    @extend %G-my-account--desktop;
    background: transparent;

    .account-menu_link-icon {
        fill: rgba(0, 0, 0, 0.65);
    }

    .my-account__title {
        background: #172F28;
        color: #E5BA55;
    }
}

.my-account-drawer {
    @extend %G-my-account-drawer;

    color: #000;

    .my-account-drawer__blocked-user {
        background: #3F4F47;
        color: #E5BA55;
    }

    .my-account-drawer__menu-list {
        background: #D2BD8D;
    }

    .my-account-drawer__menu-link {
        color: #000;

        &.active {
            background: #957D4B;
        }
    }
}

.my-account-info-list {
    @extend %G-my-account-info-list;

    .my-account-info-list__item {
        color: #000;
        background: #EEEADC;

        &:nth-child(2n) {
            background: #DED6C2;
        }

        &:not(:last-of-type) {
            border-bottom: 1px solid #2D3D37;
        }
    }

    .my-account-info-list__item-value--negative {
        color: #f16969;
    }
}

.body--with-cursor {
    .bet-delete-icon:hover {
        color: #000;
    }

    .my-account-drawer__close-btn:hover {
        color: #fff;
    }

    .my-account-drawer__menu-link:hover {
        background: #957D4B;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.1 MY ACCOUNT PAGE -> MY INFORMATION
|--------------------------------------------------------------------------
*/

.my-account--tablet,
.my-account--mobile {
    .my-info {
        @extend %G-my-info--mobile;

        .my-info__change-pass {
            color: #2D3D37;
        }
    }
}

.my-info__wrapper--tablet,
.my-info__wrapper--mobile {
    @extend %G-my-info__wrapper--mobile;
}

.my-info__wrapper--tablet {
    @extend %G-my-info__wrapper--tablet;
}

.my-info__wrapper--desktop {
    @extend %G-my-info__wrapper--desktop;

    .my-info__coll {
        background: #F1EFE7;
        color: #171F1D;
    }

    .my-info__sub-title {
        background: #3F4F47;
        color: #fff;
    }

    .my-info__row {
        border-bottom: 1px solid #171F1D;

        &:last-of-type {
            border-bottom: none;
        }
    }

    .my-info__cell-link {
        background-color: transparent;
        color: #DBBD6C;
        text-shadow: 0 0 0 #DBBD6C;
    }
}

.body--with-cursor {
    .my-info__cell-link:hover {
        text-shadow: 0 0 7px #957D4B;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.2 PAYMENTS
|--------------------------------------------------------------------------
*/
.payments {
    @extend %G-payments;

    .payments__icon {
        color: #957D4B;
    }

    .payment__btn-link-text {
        color: #957D4B;
    }
    .payment__btn-link-name {
        color: #000;
    }
}

.payments__content--mobile,
.payments__content--desktop {
    @extend %G-payments-btn-link;

    .payment {
        box-shadow: 3px 2px 5px #00000038;
        background-color: #fff;
    }
}

.payments__content--mobile {
    .payment {
        border: 1px solid #00000038;
    }
}

.payments__content--desktop {
    .payments__no-data {
        background: #3F4F47;
        color: #fff;
    }

    .payment {
        border: 1px solid #00000038;
    }
}

.payment-modal {
    @extend %G-payment-modal;
}

/*
|--------------------------------------------------------------------------
| == 3.6.4 ACCOUNT HISTORY
|--------------------------------------------------------------------------
*/

.my-account--mobile .account-history {
    .my-account-info-list {
        border-bottom-right-radius: 3px;
        border-bottom-left-radius: 3px;
        overflow: hidden;
    }
}

.account-report--mobile {
    @extend %G-account-report--mobile;

    background: #475A53;
    color: #fff;

    .account-report-item--active:not(:last-child),
    .account-report-item:not(:last-child) {
        border-right: 1px solid #172F28;
    }

    .account-report-item--active {
        background: #172F28;
        color: #E5BA55;
    }

    .ll-burger-icon--opened {
        div {
            background-color: #E5BA55;
        }
    }

    & + .my-account-info-list {
        border: 1px solid #6D684B;
        border-top: none;
    }
}

.account-report--tablet,
.account-report--desktop {
    @extend %G-account-report--desktop;

    .account-report-item {
        background: #F1EFE7;
        color: #171F1D;
    }

    .my-account-info-item__content--overflow {
        background: #F1EFE7;

        &.expanded {
            box-shadow: 0 2px 8px 0px #0000002b;
        }
    }

    .my-account-info-item__title {
        background: #3F4F47;
        color: #fff;
    }

    .my-account-info-list__item {
        border-bottom: 1px solid #162136;
    }

    .my-account-info-list__item-value--negative {
        color: #f58c89;
    }
}

.account-report--tablet {
    .account-report-item {
        border: 1px solid #C5C5C5;
    }

    .my-account-info-item__title {
        background: #716f61;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.5 BET HISTORY
|--------------------------------------------------------------------------
*/
.bet-history--mobile,
.bet-history--tablet {
    @extend %G-bet-history--mobile-tablet;

    .status-filter-switcher-switch-base {
        color: #E5BA55 !important;
    }

    .bet-history__status-active-bets,
    .bet-history__status-filter {
        color: #E5BA55;

        .ll-dropdown-menu__icon {
            color: #E5BA55;
        }
    }

    .bet-history__status-active-bets--disabled,
    .bet-history__status-filter--disabled {
        color: #C2B080;

        .ll-dropdown-menu__icon {
            color: #C2B080;
        }
    }

    .status-filter-switcher-track {
        background-color: #171F1D !important;
        opacity: 1!important;
    }

    .bet-history__status-filter {
        .bet-history__status-filter-dropdown {
            background: #2D3D37;
            border: 1px solid #172F28;
            border-top: none;

            .ll-dropdown-menu-item,
            .ll-dropdown-menu-item--disabled,
            .ll-dropdown-menu-item--active {
                color: #fff;

                &:not(:last-of-type) {
                    border-bottom: 1px solid #172F28;
                }
            }

            .ll-dropdown-menu-item--disabled {
                color: #979797;
            }

            .ll-dropdown-menu-item--active {
                border: 1px solid #2D3D37;
                background: #172F28;
            }
        }
    }
}

.bet-history--tablet {
    @extend %G-bet-history--tablet;
}

.bet-history--desktop {
    @extend %G-bet-history--desktop;

    .bet-history__cashout-cell-btn {
        background: #DBBD6C;
        color: #000;
    }

    /* ---------------------- Bet details in table ---------------------- */

    .bet-history-detail__info {
        background-color: #f1efe7;
        color: #000;
    }

    .bet-history-detail__info-row {
        border: 1px solid #0e241e;
        border-top: none;
    }

    .bet-history-detail__info-td {
        &:not(:last-child)::after {
            background-color: #000000aa;
        }
    }
}

.body--with-cursor {
    .bet-history__cashout-cell-btn:hover {
        box-shadow: inset 0 0 1px 1px #172F28;
        background: transparent;
        color: #172F28;
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.6 CASINO REPORT
|--------------------------------------------------------------------------
*/

/*
|--------------------------------------------------------------------------
| == 3.6.7 MY LIMITS
|--------------------------------------------------------------------------
*/

.my-limits {
    @extend %G-my-limits;

    .my-limits__provider-limit-info-icon {
        color: #000;
    }
}

.my-limits--mobile,
.my-limits--tablet {
    @extend %G-my-limits--mobile-table;

    .my-limits__content-box--rows .my-limits__content-box-title {
        background-color: #475a53;
        color: #fff;
    }

    .my-limits__content-box--info .my-limits__content-box-title {
        color: #e5ba55;
    }

    .my-limits__content-box-wrp {
        color: #fff;
    }

    .my-limits__content-row {
        color: #000;

        &:nth-child(2n + 1) {
            background-color: #eeeadc;
        }

        &:nth-child(2n) {
            background-color: #ded6c2;
        }
    }
}

.my-limits--desktop {
    @extend %G-my-limits--desktop;

    .my-limits__content-box {
        box-shadow: 0 0 5px 0 #00000020;
    }

    .my-limits__content-box-title {
        background: #3f4f47;
        color: #fff;
    }

    .my-limits__content-box-wrp {
        background-color: #eceadb;
    }

    .my-limits__content-row {
        &:not(:last-child) {
            border-bottom: 1px solid #172f28;
        }

        & > * {
            &:first-child {
                border-right: 1px solid #172f28;
            }
        }
    }
}

/*
|--------------------------------------------------------------------------
| == 3.6.8 MY ACCOUNT PAGE -> GOOGLE AUTHORIZATION
|--------------------------------------------------------------------------
*/
.google-auth {
    @extend %G-google-auth;

    .google-auth__content {
        border: 1px solid #172F28;
        background: #3F4F47;
        color: #fff;
    }

    .google-auth__img-qr {
        @include size(
            $size-width--cabinet-google-auth-img + px,
            $size-height--cabinet-google-auth-img + px
        );
    }

    .google-auth__submit-btn {
        background: #E5BA55;
    }

    .google-auth__qr-img-container {
        border-bottom: 1px solid #172F28;
    }

    .google-auth__install-app {
        border-bottom: 1px solid #172F28;
    }

    .google-auth__form-password,
    .google-auth__form-password--mobile {
        @extend %G-google-auth__form-password;
    }

    .google-auth__form-password--mobile {
        @extend %G-google-auth__form-password--mobile;
    }

    .google-auth__form-qr-code,
    .google-auth__form-qr-code--mobile {
        @extend %G-google-auth__form-qr-code;
    }

    .google-auth__form-qr-code--mobile {
        @extend %G-google-auth__form-qr-code--mobile;
    }
}

.my-account--mobile .google-auth {
    @extend %G-google-auth--mobile;
}

/*
|--------------------------------------------------------------------------
| == 3.6.9 MY ACCOUNT PAGE -> MESSAGES PAGE
|--------------------------------------------------------------------------
*/

.messages {
    @extend %G-messages;

    .message-item {
        background-color: #f1efe7;
        color: #171f1d;
    }

    .message-date {
        color: #3e3e3e;
    }

    .message-unread {
        background-color: #3d3b3d;

        .message-text {
            color: #fff;
        }

        .message-date {
            color: rgb(151, 151, 151);
        }
    }
}

.messages--mobile,
.messages--tablet {
    @extend %G-messages--mobile;
}

.messages--desktop {
    @extend %G-messages--desktop;
}