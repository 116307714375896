.ll-popup {
    bottom: calc(#{$size-height--footer_desktop} + 1rem);
    box-shadow: 0 3px 6px #00000029;
    color: #000;

    &.ll-popup--tablet {
        bottom: calc(#{$size-height--footer_mobile} + 1rem);
    }

    &.ll-popup--battle-upper {
        bottom: calc(#{$size-height--footer_desktop} + 10rem + 2rem);
    }

    &.ll-popup--mobile {
        bottom: calc(#{$size-height--footer_mobile} + 2rem);

        &.ll-popup--battle-upper {
            bottom: calc(#{$size-height--footer_desktop} + 10rem + 3rem);
        }
    }

    &.ll-popup--warning {
        .ll-popup__type {
            background-color: #FF0000;
            color: #fff;
        }
    }

    &.ll-popup--battle {
        .ll-popup__type {
            background-color: #E5BA55;
            color: #000000;

            &.animated {
                background-color: #D2BD8D;
            }
        }

        .ll-popup__battle-info-item::after {
            background-color: #393939;
        }

        .ll-popup__buttons .ll-popup__button--gray {
            background-color: transparent;
            border: 0.1rem solid #7C7C7C;
            color: #7C7C7C;
        }
    }

    .ll-popup__header-icon.ll-popup__header-icon--warning {
        color: #FF0000;
    }

    .ll-popup__header-icon.ll-popup__header-icon--battle {
        color: #E5BA55;
    }

    .ll-popup__type {
        background-color: #D2BD8D;
        color: #000;
    }

    .ll-popup__inner {
        background-color: rgba(255, 255, 255, 0.85);
    }

    .ll-popup__time {
        color: #8593A1;
    }

    .ll-popup__timer {
        color: #000000;
    }
}

.body--with-cursor {
    .ll-popup.ll-popup--link {
        &:hover {
            box-shadow: 0px 0px 16px 1px #D2BD8D77;
        }
    }
}

body:not(.body--with-cursor) {
    .ll-popup.ll-popup--link {
        box-shadow: 0px 0px 16px 1px #D2BD8D77;
    }
}
/*
|--------------------------------------------------------------------------
| == ACTIVE BETS & MY BETS DESKTOP POPOVER
|--------------------------------------------------------------------------
*/

.user-bets__popper-cashout {
    @extend %G-user-bets__popper-cashout;

    border: 1px solid #e5ba55;
    color: #000;
    &:before {
        border: 1px solid #e5ba55;
        background: #eeeadc;
    }
    .user-bets__popper-cashout-message {
        background: #eeeadc;
    }
}

.user-bets__popper-delete {
    @extend %G-user-bets__popper-delete;

    border: 1px solid #e5ba55;
    color: #000;
    &:before {
        border: 1px solid #e5ba55;
        background: #eeeadc;
    }
    .user-bets__popper-delete-message {
        background: #eeeadc;
    }
}