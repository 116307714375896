.pre-match-details__market-group__custom-bets {
    background: #957D4B;
}

.ll-custom-bets {
    .ll-custom-bets__info {
        background-color: #2D3D3730;
        color: #fff;
    }

    .ll-custom-bets__about-image {
        background-image: url("../../../../static/themes/theme/images/custom-bets/custom-bets.png");
    }

    .ll-custom-bets__title {
        color: #fff;
        background: #2D3D37;
        border-radius: 0;
    }

    .ll-custom-bets__about-terms-link {
        color: #E5BA55;
    }

    .ll-custom-bets__close-info {
        background-color: #D2BD8D;
        color: #000;

        &:after {
            border-top: 5px solid #D2BD8D;
        }
    }

    .ll-custom-bets__market-groups {
        background-color: #0E241E;
    }

    .ll-custom-bets__open-close-info {
        color: white;
    }

    .custom-market-group > .match-details__market-header {
        background-color: #957D4B;
    }

    .match-details__market-header {
        background-color: #C2B080;
        color: #000;
    }

    .match-details__bet--custom-unavailable {
        opacity: .5;
        cursor: not-allowed;
    }
}

.ll-scrollbox--tablet .ll-custom-bets,
.ll-scrollbox--desktop .ll-custom-bets {
    .fancy-bets-slider-wrp {
        border-top: 1px solid #172F28;
    }

    .fancy-bets-slider:nth-of-type(2n):before {
        background-color: #172F28;
    }

    .match-details__market {
        border: 1px solid #172F28;
    }

    .match-details__bets {
        border-color: #172F28;
    }

    .match-details__bet {
        color: #fff;
        background-color: #3A4E47;

        &.match-details__bet--active {
            background: #E5BA55;
            color: #000;
        }

        &.bet--column2:nth-child(2n+1):not(:only-child) {
            box-shadow: inset -1px 0 0 0 #172F28;
        }

        &.bet--column3:not(:last-child) {
            box-shadow: inset -1px 0 0 0 #172F28;
        }

        &.bet--column3:last-child {
            box-shadow: inset -1px 0 0 0 #3A4E47;
        }
    }
}

.ll-scrollbox--mobile .ll-custom-bets {
    @extend %G-custom-bets--mobile;

    .ll-custom-bets__about-text {
        color: #fff;
        padding-bottom: 3rem;
    }

    .custom-market-group > .match-details__market-header {
        background-color: #957D4B;
    }

    .match-details__market-header {
        background-color: #2D3D37;
        color: #fff;
    }

    .status-filter-switcher .status-filter-switcher-track {
        background-color: #D2BD8D !important;
    }

    .match-details__bets {
        background: #3F4F47;
    }

    .match-details__bet {
        background-color: #D2BD8D;
        color: #000;

        &.match-details__bet--active {
            border-color: #3F4F47;
            background-color: #E5BA55;
        }
    }
}

.body--with-cursor .ll-custom-bets {
    .ll-custom-bets__about-terms-link:hover {
        color: #fff;
    }

    .ll-custom-bets__close-info:hover {
        background-color: #E5BA55;
        color: #000;

        &:after {
            border-top: 5px solid #E5BA55;
        }
    }

    .ll-custom-bets__open-close-info:hover {
        border-color: #E5BA55;
        color: #E5BA55;
    }

    .ll-custom-bets-pre__market-group:hover {
        color: #000;
        background: transparent!important;
    }

    .ll-custom-bets__selected-bet-remove:hover {
        background-color: #957D4B;
        color: #fff;
    }

    .ll-custom-bets__bet-button:hover {
        background-color: #E5BA55;
        color: #000;
    }

    .match-details__bet:not(.match-details__bet--disabled):hover {
        background: #E5BA55;
        border-color: #E5BA55;
        color: #000;
    }

    .ll-custom-bets__about-info-button:hover {
        color: #E5BA55;
    }
}

.bet-builder-modal__info-header {
    color: #E5BA55;
}

.ll-custom-bets__about-info-container {

    .ll-custom-bets__about-info-button {
        color: #FFFFFF;
    }

    .ll-custom-bets__about-info-content-container {
        color: #ffffff;
        background-color: #2D3D37;
        border-color: #172F28;
    }

    .ll-custom-bets__about-info-close {
        color: #CCCCCC;
    }
}
