.vaix-widget {
    @extend %G-vaix-widget;
    --widget-item-bg: #171F1D;

    .vaix-widget__title {
        background-color: #172F28;
        color: #FFB400;
    }

    .vaix-widget-item {
        background-color: var(--widget-item-bg);
        color: #CCCCCC;
    }

    .vaix-widget-item__bet {
        &:not(:last-of-type)::before {
            background-color: #FFFFFF;
        }
    }

    .vaix-widget-item__match-icon {
        background-color: var(--widget-item-bg);
        color: #FFFFFF;

        &::before {
            box-shadow: -5px 0 0 5px var(--widget-item-bg);
        }
    }

    .vaix-widget-item__bet-name {
        color: #FFFFFF;
    }

    .vaix-widget-item__bet-value {
        color: #E5BA55;
    }

    .vaix-widget-item__submit-box {
        background-color: #172F28;
    }

    .vaix-widget-item__combined-odd-label {
        color: #FFFFFF;
    }

    .vaix-widget-item__combined-odd-value {
        color: #E5BA55;
    }

    .vaix-widget-item__submit-btn {
        background-color: #E5BA55  ;
        color: #000000;
    }

    .vaix-widget-item__submit-btn.active {
        background-color: #957D4B;
        color: #FFFFFF;
    }

    .scroll-slider__dots {
        background-color: #172F28;
        .scroll-slider__dot {
            border: 1px solid #E5BA55;
            &.filled {
                background-color: #E5BA55;
            }
        }
    }
}

.vaix-widget--desktop {
    @extend %G-vaix-widget--desktop;
}

.vaix-widget--mobile,
.vaix-widget--tablet {
    .scroll-slider__wrp {
        background-color: #272727;
    }
}

@keyframes dummyDataPulse {
    0% {
        background-color: #172F28;
    }

    50% {
        background-color: #2D3D37;
    }

    100% {
        background-color: #172F28;
    }
}

.body--with-cursor {
    .vaix-widget-item__submit-btn:not(:disabled):hover {
        background-color: #957D4B;
        color: #FFFFFF;
    }
}
