/*
4.FORMS
├── 4.1 LOGIN FORM
│   ├── 4.1.1 LOGIN FORM DESKTOP
│   └── 4.1.2 LOGIN FORM MOBILE
├── 4.2 FORGOT PASSWORD FORM
│   ├── 4.2.1 FORGOT PASSWORD MODAL FORM
│   └── 4.2.2 FORGOT PASSWORD PAGE FORM
├── 4.3 RESET PASSWORD FORM
│   ├── 4.3.1 RESET PASSWORD PAGE FORM
│   └── 4.3.2 RESET PASSWORD PAGE FORM
└── 4.4 CHANGE PASSWORD FORM
    ├── 4.4.1 CHANGE PASSWORD MODAL FORM
    └── 4.4.2 CHANGE PASSWORD PAGE FORM
*/

/*
| == 4.FORMS
|--------------------------------------------------------------------------
| == 4.1 LOGIN FORM
|--------------------------------------------------------------------------
| == 4.1.1 LOGIN FORM DESKTOP
|--------------------------------------------------------------------------
*/

.form__captcha-text {
    color: #888888;

    .form__captcha-link {
        color: #E5BA55;
    }
}

.login-form__signup-link {
    color: #E5BA55;
}

.ll-login-form-modal {
    .login-form__login-btn {
        background: #E5BA55;
    }

    .login-form__error {
        color: #f44336;
        text-align: center;
    }

    .ll-input__text-box-container {
        &:focus-within {
            box-shadow: 0 0 8px 1px #E5BA5577;
        }
    }

    .login-form--desktop {
        @extend %G-login-form;
        @extend %G-login-form--desktop;
    }

    .ll-scrollbox--desktop {
        border-radius: 0 0 3px 3px;
    }
}

.body--with-cursor {
    .login-form__login-btn:hover {
        background: #C2B288;
        color: #000;
        opacity: 1;
    }
}

/*
|--------------------------------------------------------------------------
| == 4.1.2 LOGIN FORM MOBILE
|--------------------------------------------------------------------------
*/

.login-form--tablet,
.login-form--mobile {
    @extend %G-login-form;

    .login-form__error {
        color: #f44336;
        text-align: center;
    }

    .login-form__forgot-pwd-btn {
        text-align: center;
        font-size: 1.4rem;
        color: #E5BA55;
    }

    .dx-texteditor.dx-editor-outlined.dx-state-focused {
        -webkit-box-shadow: inset 0 0 0 2px #E5BA5577 !important;
        box-shadow: 0 0 8px 1px #E5BA5577 !important;
    }

    .login-form__close-btn,
    .login-form__login-btn {
        text-align: center;
        text-transform: uppercase;
    }
}

.login-drawer__logo {
    @extend %G-login-drawer__logo;

    background-image: url('../../../../static/themes/theme/images/logos/logo.png');
    background-size: cover;
    @include size(11rem, 5.8rem);
}

/*
|--------------------------------------------------------------------------
| == 4.2 FORGOT PASSWORD FORM
|--------------------------------------------------------------------------
|--------------------------------------------------------------------------
| == 4.2.1 FORGOT PASSWORD MODAL FORM
|--------------------------------------------------------------------------
*/

.forgot-password-form {
    @extend %G-forgot-password-form;
}

.ll-forgot-password-modal {
    .forgot-password-captcha--error,
    .forgot-password-captcha--error .dx-item-content {
        font-size: 1.2rem !important;
        color: red;
        text-align: center;
    }

    .ll-input__text-box-container {
        &:focus-within {
            box-shadow: 0 0 8px 1px #E5BA5577;
        }
    }

    .ll-scrollbox--desktop {
        border-radius: 0 0 3px 3px;
    }
}

/*
|--------------------------------------------------------------------------
| == 4.2.2 FORGOT PASSWORD PAGE FORM
|--------------------------------------------------------------------------
*/

.forgot-password--mobile,
.forgot-password--tablet {
    @extend %G-forgot-password--mobile;

    color: #fff;

    .forgot-password__header {
        font-size: 2.4rem;
        text-align: center;
        line-height: 3rem;
    }

    .ll-input__text-box-container {
        &:focus-within {
            box-shadow: 0 0 8px 1px #E5BA5577;
        }
    }
}

.forgot-password--tablet {
    @extend %G-forgot-password--tablet;
}

/*
|--------------------------------------------------------------------------
| == 4.3 RESET PASSWORD FORM
|--------------------------------------------------------------------------
|--------------------------------------------------------------------------
| == 4.3.1 RESET PASSWORD FORM
|--------------------------------------------------------------------------
*/

.reset-password--desktop {
    @extend %G-reset-password--desktop;

    background: #2D3D37;
    color: #fff;
    font-size: 0.9rem;

    .reset-password__header {
        font-size: 2.4rem;
        text-align: center;
        line-height: 3rem;
    }

    .reset-password__wrong-code {
        font-size: 1.2rem;
        text-align: center;
        color: #C73738;
    }

    .password-strength__label {
        text-align: left;
        font-size: 1.5rem;
    }

    .ll-input__text-box-container {
        box-shadow: 0 0 8px 1px #E5BA5577;
    }
}

.body--with-cursor {
    .reset-password__submit-btn:hover {
        border: 1px solid #E5BA55;
        background: transparent;
        color: #E5BA55;
    }
}

/*
|--------------------------------------------------------------------------
| == 4.3.2 RESET PASSWORD PAGE FORM
|--------------------------------------------------------------------------
*/

.reset-password--tablet,
.reset-password--mobile {
    @extend %G-reset-password--mobile;

    color: #fff;

    .reset-password__header {
        font-size: 1.4rem;
        text-align: center;
        line-height: 3rem;
    }

    .reset-password__wrong-code {
        font-size: 1.2rem;
        text-align: center;
        color: #C73738;
    }

    .password-strength__label {
        text-align: left;
        font-size: 1.2em;
    }
}

.reset-password--tablet {
    @extend %G-reset-password--tablet;

    border: 1px solid #3F4F47;
}

.reset-password--mobile {
    flex: 1;
}

/*
|--------------------------------------------------------------------------
| == 4.4 CHANGE PASSWORD FORM
|--------------------------------------------------------------------------
*/
.change-password {
    @extend %G-change-password;
}

/*
|--------------------------------------------------------------------------
| == 4.4.1 CHANGE PASSWORD MODAL FORM
|--------------------------------------------------------------------------
*/

.modal-change-password {

    .ll-input__text-box-container {
        &:focus-within {
            box-shadow: 0 0 8px 1px #E5BA5577;
        }
    }
    .change-password__header {
        font-size: 1.4rem;
        text-align: center;
        line-height: 3rem;
    }

    .dx-invalid-message > .dx-overlay-content {
        font-size: 0.75em;
    }

    .password-strength__label {
        text-align: center;
        font-size: 1.8rem;
    }

    .password-strength__texts {
        color: #000;
    }
}

/*
|--------------------------------------------------------------------------
| == 4.4.2 CHANGE PASSWORD PAGE FORM
|--------------------------------------------------------------------------
*/

.change-password--tablet,
.change-password--mobile {
    @extend %G-change-password--mobile;

    color: #fff;

    .ll-input__text-box-container {
        &:focus-within {
            box-shadow: 0 0 8px 1px #E5BA5577;
        }
    }

    .change-password__header {
        font-size: 2.4rem;
        text-align: center;
        line-height: 3rem;
    }

    .password-strength__label {
        text-align: left;
        font-size: 1.2em;
    }
}

/*
|--------------------------------------------------------------------------
| == 4.4.3(NEW) Google Authenticator
|--------------------------------------------------------------------------
*/

.google-auth__input {
    &:focus-within {
        box-shadow: 0 0 8px 1px #E5BA5577;
    }
}